@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-Inter;
  
}
.btn-claim-diable {
  background: #4d6064;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #888f91, #4d6064);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #888f91, #4d6064); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fff;
  /* border: 3px solid #eee; */
  border-radius: 30px;
}
.btn-claim-active {
  background: #2193b0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #6dd5ed, #2193b0);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #6dd5ed, #2193b0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fff;
  /* border: 3px solid #eee; */
  border-radius: 30px;
}
.button-container {
  .button-container {
    border: 2px solid black;
    padding: 10px;
    border-radius: 7px;
    box-shadow: inset 0 20px 4px -19px rgba(255, 255, 255, 0.7);
  }
  
  .button-container button {
    cursor: pointer;
    outline: none;
    width: 120px;
    height: 75px;
    font-family: Helvetica, sans-serif;
    font-size: 25px;
    color: white;
    text-shadow: 0 2px 5px black;
    background: linear-gradient(to top, #696969, #575757);
    border: 2px solid black;
    border-radius: 7px;
    box-shadow: inset 0 20px 4px -19px rgba(255, 255, 255, 0.4), 0 12px 12px 0 rgba(0, 0, 0, 0.3);
  }
  
  .button-container button:hover {
    background: linear-gradient(to bottom, #696969, #575757);
  }
  
  .button-container button:active {
    transform: translateY(3px);
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.3);
  }
  
}
/* User Wallet
----------------------------------------------------------*/
#user_wallet > div:first-child > div,
#user_wallet > div:last-child > div {
  width: 100%;
}
#user_wallet > div:first-child img {
  width: 16px;
}
#user_wallet > div:last-child img {
  width: 24px;
}

/* Scrollbar
----------------------------------------------------------*/
*::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #000;
}
*::-webkit-scrollbar-thumb {
  background-color: #cec65d;
  outline: none;
  border-radius: 500px;
}

/* Unlock Modal
----------------------------------------------------------*/
#unlock_modal .unlock-items:first-of-type,
#addslot_modal .addslot-item:first-of-type {
  margin-bottom: 15px;
}
#unlock_modal .unlock-items:first-of-type img,
#addslot_modal .addslot-item:first-of-type img {
  width: 32px;
  margin: auto;
}


textarea:focus, input:focus, select:focus{
  outline: none;
}

/* Unlock Modal
----------------------------------------------------------*/
.locked {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
  filter: blur(4px);
  transition: all 0.3s ease;
}

.unlocked {
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;
  filter: none;
  transition: all 0.3s ease;
}
