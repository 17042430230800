.btn {
  @apply px-3 py-1.5 cursor-pointer;
}
.btn-default {
  @apply bg-gradient-to-b from-gray-700 to-black  border text-olive border-profile-green;
}
.btn-profile {
  @apply bg-gradient-to-b from-gray-700 to-black border text-olive border-profile-green;
}

.custom-shadow {
  box-shadow: #a5a5a5eb 0px 0px 7px -3px !important ;
}

.active {
  color: #e2e3e6;
}

.scroll-to-top {
  background-color: #D1C6A2 !important;
  width: 64px !important;
  height: 64px !important;
}

.circle-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff; /* Change to your border color */
  overflow: hidden;
}

.circle-progress-bar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: rotate(-90deg);
}

.circle-progress-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 108px; /* This should be double the radius */
  height: 108px; /* This should be double the radius */
}

.circle-progress-percentage {
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: white;
}