@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);

@tailwind base;
@tailwind components;
@tailwind utilities;


.buttonContainer {
  border: 2px solid black;
  padding: 5px;
  border-radius: 7px;
  box-shadow: inset 0 20px 4px -19px rgba(255, 255, 255, 0.7);
}

.buttonContainer button {
  cursor: pointer;
  outline: none;
  font-family: Helvetica, sans-serif;
  font-size: 18px;
  color: white;
  text-shadow: 0 2px 5px black;
  background: linear-gradient(to top, #696969, #575757);
  border: 2px solid black;
  border-radius: 7px;
  box-shadow: inset 0 20px 4px -19px rgba(255, 255, 255, 0.4), 0 12px 12px 0 rgba(0, 0, 0, 0.3);
  opacity: 0.5;
}

.buttonContainer button:hover {
  background: linear-gradient(to bottom, #696969, #575757);
}

.buttonContainer button:active {
  transform: translateY(3px);
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.3);
}
